body {
    background-color: #111111;
    color: #bbbbbb;
    margin: 0;
    font-family: Helvetica Neue, sans-serif;
}

.navbar {
    background-color: #333333;
    overflow: hidden;
}

.navbar a {
    color: #bbbbbb;
    text-align: center;
    padding: 14px 16px;
    font-size: 1em;
    text-decoration: none;
}

.navbar a.left {
    float: left;
}

.navbar a.right {
    float: right;
}

.navbar a:hover {
    background-color: #bbbbbb;
    color: #111111;
}

.centered {
    text-align: center;
}

hr.break {
    margin: 2em;
}

.disabled-link {
    pointer-events: none;
}

a {
    color: #ffffff;
}

.features {
    margin-top: 1em;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.feature {
    margin: 1em;
    height: 110px;
    flex-basis: 300px;
}

.screenshot {
    margin: 1em;
    flex-basis: 300px;
}

.downloads {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.downloads a {
    text-decoration: none;
}

.download-link {
    background-color: #4700ab;
    margin: 2em;
    padding: 1.5em;
    border-radius: 12px;
    font-size: 18px;
}

.download-link.disabled-link {
    background-color: #2d0073;
}

.download-link:hover {
    background-color: #6000da;
}

.left-margin {
    margin-left: 1em;
}

.demo-frame {
    width: 560px;
    height: 315px;
}

@media screen and (max-width: 560px) {
    .demo-frame {
        width: 350px;
        height: 206px;
    }
}

#demo-video {
    max-width: 90%;
    max-height: 360px;
}

li.summary {
    margin-top: 0.5em;
}

li.question {
    color: #8f4eda;
    font-size: 1.25em;
    margin-top: 1em;
    margin-right: 0.75em;
    list-style: none;
}

li.answer {
    margin-right: 1em;
}

ol.instructions li {
    margin-top: 0.5em;
}

ol.instructions li pre {
    background-color: #333333;
    padding: 0.4em;
    border-radius: 5px;
    display: inline-block;
}